
import './App.css';
import Greeting from './copm/Greeting';

function App() {
  return (
    <div style={{textAlign:'center',marginTop:'50px'}}>
     <h1>React კომპონენტები და პროპსები
      <Greeting name='ნინო' message='კეთილი იყოს შენი მობრძანება!'/>
      <Greeting name='გიორგი' message='გისურვებ წარმატებას!'/>
      <Greeting name='მარიამ' message='შენ საუკეთესო ხარ!'/>
     </h1>
    </div>
  );
}

export default App;
